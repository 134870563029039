<template>
  <!--   BAN BUTTON   -->
  <div class="flex flex-row" :class="{'group-hover:flex': isThread, 'gap-1': isThread, 'gap-2': !isThread}">
    <!-- Thread not banned -->
    <template v-if="!currentThread.bannedAt">
      <VTooltip>
        <Button
            variant="iconThreadAction"
            @clicked="openReportUserModal(true)"
        >
          <base-icon :width="!isThread ?16 : 14" :height="!isThread ?16 : 14" name="lockFilled">
          </base-icon>
        </Button>
        <template #popper>
          {{ trans('TOOLTIP:Block') }}
        </template>
      </VTooltip>
    </template>
    <!-- Thread is banned -->
    <template v-else>
      <VTooltip>
        <Button
            variant="iconThreadAction"
            @clicked="banAction(false)"
        >
          <base-icon :width="!isThread ?16 : 14" :height="!isThread ?16 : 14" name="lockOpen">
          </base-icon>
        </Button>
        <template #popper>
          {{ trans('TOOLTIP:Unblock') }}
        </template>
      </VTooltip>
    </template>
    <!-- BAN BUTTON END -->

    <!-- DELETE BUTTON -->
	  <template>
		  <VTooltip>
			  <Button
					  variant="iconThreadAction"
					  @clicked="openDeleteChatModal(true, currentThread)"
			  >
				  <base-icon :width="!isThread ?16 : 14" :height="!isThread ?16 : 14" name="trash">
				  </base-icon>
			  </Button>
			  <template #popper>
				  {{ trans('TOOLTIP:DeleteChat') }}
			  </template>
		  </VTooltip>
	  </template>
    <!-- DELETE BUTTON END -->

    <!-- ADD TO FAVOURITES BUTTON -->
    <template v-if="currentThread.bannedAt === null">
      <template v-if="!currentThread.userFavouritedBy">
        <VTooltip>
          <Button
              variant="iconThreadAction"
              @clicked="addToFavUsers()"
          >
            <base-icon :width="!isThread ?16 : 14" :height="!isThread ?16 : 14" name="heartFilled">
            </base-icon>
          </Button>
          <template #popper>
            {{ trans('TOOLTIP:AddToFavourites') }}
          </template>
        </VTooltip>
      </template>
      <template v-else>
        <VTooltip>
          <Button
              variant="iconThreadAction"
              @clicked="removeFromFavUsers()"
          >
            <base-icon :width="!isThread ?16 : 14" :height="!isThread ? 16 : 14" name="heart">
            </base-icon>
          </Button>
          <template #popper>
            {{ trans('TOOLTIP:RemoveFromFavourites') }}
          </template>
        </VTooltip>
      </template>
    </template>
    <!-- ADD TO FAVOURITES BUTTON END -->

    <Modal
        v-if="showReportUserModal"
        :showModal="showReportUserModal"
        :buttonConfirmText="trans('BUTTON:Yes')"
        @onConfirm="openContactModal(true)"
        :loading="banLoading"
        @clicked="openReportUserModal(false)"
        :header="trans('MODAL_TITLE:ReportUser')"
        :data="{username: currentThread.username}"
    >
      <template v-slot:content>
        <div class="text-base">
          {{ trans('MODAL_DESCRIPTION:ReportUser') }}
        </div>
      </template>
	    <template v-slot:footer>
		    <Button
				    variant="buttonRedOutlined"
				    :text="trans('BUTTON:BanUserAndReport')"
				    @clicked="openContactModal(true)"
		    >
		    </Button>
		    <Button
				    :text="trans('BUTTON:BanUser')"
				    @clicked="banAction(true)"
		    >
		    </Button>
	    </template>
    </Modal>
    <Modal
        v-if="isShowContactModal"
        :showModal="isShowContactModal"
        :hide-footer="true"
        @clicked="openContactModal(false)"
        @onConfirm="deleteMessageThread()"
        :header="trans('MODAL_TITLE:ContactForm')"
    >
      <template v-slot:content>
        <ContactForm :reported-user="currentThread.targetUser" :type="'VIOLATION'" @send-admin-message="banAction(true)" />
      </template>
    </Modal>

	  <Modal
			  v-if="isShowDeleteChatModal"
			  :showModal="isShowDeleteChatModal"
			  @clicked="openDeleteChatModal(false)"
			  :header="trans('MODAL_TITLE:DeleteChat')"
			  :buttonConfirmText="trans('BUTTON:Delete')"
			  :loading="threadDeleting"
			  @onConfirm="deleteMessageThread"
	  >
		  <template v-slot:content>
			  <span class="text-base">{{ trans('MODAL_DESCRIPTION:DeleteChat') }}</span>
		  </template>
	  </Modal>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import {mapActions, mapGetters} from "vuex";
import Modal from "@/utils/modal/Modal";
import ContactForm from "@/views/content/components/ContactForm";
export default {
  name: "ThreadActions",
  components: {ContactForm, Modal, BaseIcon, Button},
  props: {
    isThread: Boolean,
    visibleOnHover: Boolean,
    currentThread: Object,
  },
  data() {
    return {
      showReportUserModal: false,
      iconLockFilled: false,
      iconUnlockFilled: false,
      iconHeartFilled: false,
      isShowContactModal: false,
	    isShowDeleteChatModal: false,
	    threadToDelete: null,
    }
  },
  watch: {
    'currentThread.bannedAt': {

      immediate: true,
      deep: true,
      handler(val) {
        this.iconUnlockFilled = val !== null;
      }
    },
    adminMessageSent(val) {
      if(val) {
        this.openReportUserModal(false);
        this.openContactModal(false);
      }
    },
	  threadDeleted(val) {
			if(val) {
				this.openDeleteChatModal(false);
				this.threadToDelete = null;
			}
	  },
	  banned(val) {
			if(val) {
				this.openReportUserModal(false);
				this.openContactModal(false);
			}
	  }
  },
  computed: {
    ...mapGetters('chat', [ 'banLoading', 'banned', 'threadDeleted', 'threadDeleting']),
    ...mapGetters('contact', ['adminMessageSent']),

	  getIconSize() {
			return this.isThread ? 12 : 16;
	  }
  },
  methods: {
    ...mapActions('favourites', ['removeFavouriteProfile', 'addFavouriteProfile']),
    ...mapActions('chat', ['banThreadRequest', 'deleteThreadRequest']),

    addToFavUsers() {
      this.addFavouriteProfile({favouriteUsername: this.currentThread.targetUser.username});
    },

    banAction(value) {
      if(this.currentThread.userFavouritedBy) {
        this.removeFromFavUsers();
      }
      this.banThreadRequest({
        threadId: this.currentThread.id,
        ban: value,
      });
      this.$root.$emit('ban-action')
    },

    deleteMessageThread() {
      this.deleteThreadRequest({threadId: this.threadToDelete.id})
      this.$root.$emit('delete-fav-action')
    },

    openReportUserModal(value, ban = false) {
      this.showReportUserModal = value;

      if(ban) {
        this.banAction(true);
      }
    },

    openContactModal(value, ban = false) {
      this.isShowContactModal = value;

      if(ban) {
        this.banAction(true);
      }
    },

	  openDeleteChatModal(value, threadToDelete = null) {
			this.isShowDeleteChatModal = value;
			this.threadToDelete = threadToDelete;
	  },

    removeFromFavUsers() {
      this.removeFavouriteProfile({favouriteUsername: this.currentThread.targetUser.username})
      this.$root.$emit('unfav-action')
    },
  },
  mounted() {
    this.iconLockFilled = this.currentThread.bannedAt !== null
    this.iconHeartFilled = this.currentThread.userFavouritedBy
  }
}
</script>

<style>

</style>
