var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"group flex flex-row gap-3 items-center text-xs transition duration-300 ease-in-out\n    cursor-pointer focus:outline-none opacity-70 hover:opacity-100",class:{
      'cursor-pointer':_vm.currentThreadId != _vm.thread.id,
      'opacity-100': _vm.currentThreadId === _vm.thread.id,
     }},[_c('div',{staticClass:"flex flex-row items-start flex-none"},[_c('div',{staticClass:"flex-none"},[_c('Picture',{class:{
              'cursor-pointer scale-100 lg:group-hover:scale-105':_vm.currentThreadId != _vm.thread.id,
              'border-redAccentLight lg:scale-105': _vm.currentThreadId === _vm.thread.id,
          },attrs:{"is-chat":"","src":_vm.systemAvatar,"classes":"object-cover w-11 h-11 rounded-full\n              transform duration-200 ease-out border border-black\n              group-hover:border-redAccentLight"}})],1)]),_c('div',{staticClass:"w-full flex flex-row justify-between"},[_c('div',{staticClass:"flex flex-col justify-between"},[_c('div',{staticClass:"flex flex-row justify-between gap-2 items-start w-min "},[(_vm.thread.targetUser)?_c('span',{staticClass:"block whitespace-nowrap text-base  leading-normal\n            group-hover:text-redAccentLight\n            flex flex-row mt-1 mb-1 items-start items-center gap-1",class:{
              'cursor-pointer group-hover:opacity-70':_vm.currentThreadId != _vm.thread.id,
              'text-redAccentLight': _vm.currentThreadId === _vm.thread.id,
            }},[_vm._v(" "+_vm._s(_vm.trans('LABEL:AdministratorName'))+" ")]):_vm._e(),_c('div',[(_vm.notReadThreadMessages(_vm.thread) > 0)?[_c('t-tag',{staticClass:"w-min whitespace-nowrap",attrs:{"tag-name":"span","variant":"counterSuccess"}},[_vm._v(" "+_vm._s(_vm.notReadThreadMessages(_vm.thread)>100?'100+':_vm.notReadThreadMessages(_vm.thread))+" ")])]:_vm._e()],2)]),_c('div',{staticClass:"flex flex-row justify-between"},[_c('span',{staticClass:"block text-s text-grayedDark thread-span\n            group-hover:text-redAccentLight",class:{
            'cursor-pointer group-hover:opacity-70':_vm.currentThreadId != _vm.thread.id,
            'text-redAccentLight opacity-100': _vm.currentThreadId === _vm.thread.id
          }},[_c('div',{staticClass:"truncate whitespace-nowrap",class:_vm.getWidth('ThreadCard', 356)},[_vm._v(" "+_vm._s(_vm.stripTags(_vm.thread.latestMessage))+" ")])])])])])]),(_vm.index !== _vm.threadsDataLength - 1)?_c('hr',{staticClass:" mt-2 mb-2 border-solid border-grayedHR"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }