<template>
  <div>
    <a class="group flex flex-row gap-3 items-center text-xs transition duration-300 ease-in-out
      cursor-pointer focus:outline-none opacity-70 hover:opacity-100"
      :class="{
        'cursor-pointer':currentThreadId != thread.id,
        'opacity-100': currentThreadId === thread.id,
       }"
    >
      <!-- User image with new message dot -->
      <div class="flex flex-row items-start flex-none">
        <!-- User image -->
        <div class="flex-none">
          <Picture
              is-chat
              :src="systemAvatar"
              classes="object-cover w-11 h-11 rounded-full
                transform duration-200 ease-out border border-black
                group-hover:border-redAccentLight"
              :class="{
                'cursor-pointer scale-100 lg:group-hover:scale-105':currentThreadId != thread.id,
                'border-redAccentLight lg:scale-105': currentThreadId === thread.id,
            }"
          />
        </div>
      </div>
      <div class="w-full flex flex-row justify-between">
        <div class="flex flex-col justify-between">
          <!-- Username -->
          <div class="flex flex-row justify-between gap-2 items-start w-min ">
            <span class="block whitespace-nowrap text-base  leading-normal
              group-hover:text-redAccentLight
              flex flex-row mt-1 mb-1 items-start items-center gap-1"
              :class="{
                'cursor-pointer group-hover:opacity-70':currentThreadId != thread.id,
                'text-redAccentLight': currentThreadId === thread.id,
              }"
              v-if="thread.targetUser"
            >
              {{ trans('LABEL:AdministratorName') }}
            </span>
            <!-- New message dot -->
            <div>
              <template v-if="notReadThreadMessages(thread) > 0">
                <t-tag class="w-min whitespace-nowrap" tag-name="span" variant="counterSuccess">
                  {{ notReadThreadMessages(thread)>100?'100+':notReadThreadMessages(thread)}}
                </t-tag>
              </template>
            </div>
          </div>
          <!-- Message excerpt -->
          <div class="flex flex-row justify-between">
            <span class="block text-s text-grayedDark thread-span
              group-hover:text-redAccentLight"
              :class="{
              'cursor-pointer group-hover:opacity-70':currentThreadId != thread.id,
              'text-redAccentLight opacity-100': currentThreadId === thread.id
            }"
            >
              <div :class="getWidth('ThreadCard', 356)" class="truncate whitespace-nowrap" >
                {{ stripTags(thread.latestMessage) }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </a>
    <hr class=" mt-2 mb-2 border-solid border-grayedHR" v-if="index !== threadsDataLength - 1" />
  </div>
</template>

<script>
import Dot from "@/utils/dots/Dot";
import {mapGetters} from "vuex";
import ThreadActions from "@/views/content/messages/components/ThreadActions";
import {getWidth, stripTags} from "@/common/helpers/utils";
import themeConfig from "@/themeConfig";

export default {
  name: "ThreadCardAdminMessages",
  components: {ThreadActions, Dot},
  props: {
    currentThread: Object,
    thread: Object,
    index: [String, Number],
    threadsDataLength: Number,
    isBanned: Boolean,
    isAdminMessage: true,
  },
  data() {
    return {
      stripTags: stripTags,
      getWidth: getWidth,
      systemAvatar: themeConfig.app.logo,
    }
  },
  computed: {
    ...mapGetters('chat', ['notReadCounter']),
    ...mapGetters('account', ['userData']),
    currentThreadId() {
      return this.currentThread ? this.currentThread.id : null;
    },
  },
  methods: {
    notReadThreadMessages(thread) {
      if(this.notReadCounter) {
        return this.notReadCounter.threadsCounter.find(el => el.threadId === thread.id)?.messagesNotRead
      }
      return 0;
    },
  }
}
</script>

<style>

.img-gray {
  filter: grayscale(1);
}

</style>