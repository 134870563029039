<template>
  <t-card class="flex flex-col w-full lg:h-088screen overflow-y-scroll">
    <TextField
        :placeholder="trans('LABEL:searchInput')"
        v-model="searchThreads"
        @input="filterThreads"
        :custom-class="'pl-8'"
    >
      <template v-slot:iconFirst>
        <base-icon class="absolute h-4 w-4 top-1/2 transform -translate-y-1/2 left-3" name="search">
        </base-icon>
      </template>
      <template v-slot:iconEnd>
        <CloseButton
          @clicked="searchThreads === null"
          :show="searchThreads !== null"
        />
      </template>
    </TextField>

    <SidebarSectionSeparatorLine/>

    <template v-if="isFiltering">
      <ListHeaderSection
        :text="trans('LABEL:ThreadsSearchResult')"
        :count="filteredThreads.length"
      >
        <base-icon name="search">
        </base-icon>
      </ListHeaderSection>
      <ul v-if="filteredThreads.length > 0">
        <li
          @click.stop="!isCurrentThread(thread.id) ? openChat(thread) : null"
          v-for="(thread, index) in filteredThreads"
          :key="thread.id"
        >
          <ThreadCard
            :current-thread="currentThread ? currentThread :thread"
            :thread="thread"
            :index="index"
            :threads-data-length="filteredThreads.length"
          >
          </ThreadCard>
          <ThreadSeparatorLine v-if="index !== filteredThreads.length - 1" />
        </li>
      </ul>
      <ul v-else>
        <li>
        </li>
      </ul>
    </template>

    <template v-else>
      <ListHeaderSection
        :text="trans('LABEL:ThreadsFavourite')"
        :count="threadsFavourites.length"
        :total="Number(configData.userFavouritesLimit)"
        :isFavourite=true
        :class="{'cursor-default':threadsFavourites.length>0}"
      >
        <base-icon :width="15" :height="14" name="heartFilled">
        </base-icon>
      </ListHeaderSection>
      <ul v-if="threadsFavourites.length > 0">
        <transition-group name="slide">
          <li
              v-for="(thread, index) in threadsFavourites" :key="thread.id"
              @click="!isCurrentThread(thread.id) ? openChat(thread) : null"
          >
            <ThreadCard
              :current-thread="currentThread"
              :thread="thread"
              :index="index"
              :threads-data-length="threadsFavourites.length">
            </ThreadCard>
            <ThreadSeparatorLine v-if="index !== threadsFavourites.length - 1" />
          </li>
        </transition-group>
      </ul>
      <ul v-else>
        <li class="text-grayed text-s mt-3 lg:mt-4">
          {{ trans('MESSAGE:PlaceForFavouriteChats') }}
        </li>
      </ul>

      <SidebarSectionSeparatorLine/>

      <ListHeaderSection
        :text="trans('LABEL:ThreadsRegular')"
        :count="threadsRegular.length"
        v-on="threadsRegular.length>0 ? { clicked: toggleRegularThreadsAction } : {}"
        :class="{'cursor-default':threadsFavourites.length>0}"
      >
        <template v-slot:action v-if="threadsRegular.length>0">
          <t-button variant="iconDropdown">
            <base-icon v-if="showRegularThreads" name="chevronUp"></base-icon>
            <base-icon v-else name="chevronDown"></base-icon>
          </t-button>
        </template>
        <base-icon :width="20" :height="20" name="chats">
        </base-icon>
      </ListHeaderSection>
      <ul v-if="threadsRegular.length > 0">
        <transition-group name="slide">
          <li
            @click.stop="!isCurrentThread(thread.id) ? openChat(thread) : null"
            v-for="(thread, index) in threadsRegular"
            :key="thread.id" v-show="showRegularThreads"
          >
            <ThreadCard
              :current-thread="currentThread"
              :thread="thread"
              :threads-data-length="threadsRegular.length"
              :index="index"
            >
            </ThreadCard>
            <ThreadSeparatorLine v-if="index !== threadsRegular.length - 1" />
          </li>
        </transition-group>
      </ul>
      <ul v-else>
        <li class="text-grayed text-s mt-3 lg:mt-4">
          {{ trans('MESSAGE:PlaceForChats') }}
        </li>
      </ul>

      <SidebarSectionSeparatorLine v-if="threadsBanned.length > 0" />

      <ListHeaderSection
        v-if="threadsBanned.length > 0"
        :text="trans('LABEL:ThreadsBlocked')"
        :count="threadsBanned.length"
        v-on="threadsBanned.length > 0 ? { clicked: toggleBlockedThreadsAction } : {}"
        :class="{'cursor-default': threadsFavourites.length > 0}"
      >
        <base-icon name="lockFilled">
        </base-icon>
        <template v-slot:action v-if="threadsBanned.length > 0">
          <t-button variant="iconDropdown">
            <base-icon v-if="showBlockedThreads" name="chevronUp"></base-icon>
            <base-icon v-else name="chevronDown"></base-icon>
          </t-button>
        </template>
      </ListHeaderSection>
      <ul v-if="threadsBanned.length > 0">
        <transition-group name="slide">
          <li @click="!isCurrentThread(thread.id) ? openChat(thread) : null"
            v-for="(thread, index) in threadsBanned"
            :key="thread.id"
            v-show="showBlockedThreads"
          >
            <ThreadCard
              :current-thread="currentThread"
              :thread="thread"
              :is-banned="true"
              :threads-data-length="threadsBanned.length"
              :index="index"
            >
            </ThreadCard>
            <ThreadSeparatorLine v-if="index !== threadsBanned.length - 1" />
          </li>
        </transition-group>
      </ul>

      <SidebarSectionSeparatorLine v-if="threadsAdminMessages.length > 0" />

      <ListHeaderSection
        v-if="threadsAdminMessages.length > 0"
        :text="trans('LABEL:ThreadsAdminMessages')"
        :count="threadsAdminMessages.length"
        v-on="threadsAdminMessages.length > 0 ? { clicked: toggleAdminMessagesThreads } : {}"
        :class="{'cursor-default': threadsAdminMessages.length > 0}"
      >
        <base-icon name="bug">
        </base-icon>
        <template v-slot:action v-if="threadsAdminMessages.length > 0">
          <t-button variant="iconDropdown">
            <base-icon v-if="showAdminMessagesThreads" name="chevronUp"></base-icon>
            <base-icon v-else name="chevronDown"></base-icon>
          </t-button>
        </template>
      </ListHeaderSection>
      <ul v-if="threadsAdminMessages.length > 0">
        <transition-group name="slide">
          <li @click="!isCurrentThread(thread.id) ? openChat(thread) : null"
            v-for="(thread, index) in threadsAdminMessages" :key="thread.id"
            v-show="showAdminMessagesThreads"
          >
            <template v-if="!userData.isSuperAdmin && thread.isAdminMessage">
              <ThreadCardAdminMessages
                :current-thread="currentThread"
                :thread="thread"
                :index="index"
                :threads-data-length="threadsAdminMessages.length"
              />
            </template>
            <template v-else>
              <ThreadCard
                :current-thread="currentThread"
                :thread="thread"
                :threads-data-length="threadsAdminMessages.length"
                :index="index"
              >
              </ThreadCard>
              <ThreadSeparatorLine v-if="index !== threadsAdminMessages.length - 1" />
            </template>
          </li>
        </transition-group>
      </ul>
    </template>
  </t-card>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Vue from 'vue';
import { mdiCheck, mdiCheckAll } from '@mdi/js'
import {userAuthService} from "@/common/services/auth.service";
import TextField from "@/utils/crud/components/field-types/Text";
import Dot from "@/utils/dots/Dot";
import ThreadCard from "@/views/content/messages/components/ThreadCard";
import ListHeaderSection from "@/views/content/messages/components/ListHeaderSection";
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import {isEmpty} from "@/common/helpers/utils";
import router from "@/router";
import ThreadCardAdminMessages from "@/views/content/messages/components/ThreadCardAdminMessages.vue";
import sortBy from 'lodash/sortBy';
import CheckboxField from "@/utils/crud/components/field-types/Checkbox.vue";
import CloseButton from "@/utils/crud/components/CloseButton.vue";
import SidebarSectionSeparatorLine from "@/views/content/components/SidebarSectionSeparatorLine"
import ThreadSeparatorLine from "@/views/content/messages/components/ThreadSeparatorLine"

export default {
  name: "Threads",
  components: {
	  CloseButton,
    CheckboxField,
    ThreadCardAdminMessages,
    Button,
    BaseIcon, ListHeaderSection, ThreadCard, Dot, TextField, ThreadSeparatorLine, SidebarSectionSeparatorLine
  },
  props: {
    userData: {
      type: Object,
    }
  },
  data() {
    return {
      newThreads: [],
      threadsBanned: [],
      threadsRegular: [],
      threadsFavourites: [],
      threadsAdminMessages: [],
      searchThreads: null,
      filteredThreads: [],
      showRegularThreads: true,
      showBlockedThreads: false,
      showAdminMessagesThreads: false,
      isFiltering: false,
    }
  },
  computed: {
    ...mapGetters('chat',
    [
      'threadsData', 'currentThread', 'chosenUser',
      'threadIdSeen', 'threadsLoaded', 'messagesData', 'notReadCounter',
      'isInputActive', 'messageSent', 'chatActiveMobile'
    ]),
    ...mapGetters('lang', ['langLocale', 'configData']),
    ...mapGetters('account', ['authDone']),


    dontShowDeletedUsers: {
      get() {
        if(this.$store.getters['chat/dontShowDeletedUsers'] === null) {
          return this.userData.dontShowDeletedUsers
        }
        return this.$store.getters['chat/dontShowDeletedUsers'];
      },
      set(value) {
        this.sendDontShowDeletedUsersRequest({dontShowDeletedUsers: value})
      }
    },
  },
  watch: {
    messagesData(val) {
      if(val.length > 0 && this.currentThread) {
        let currThread = this.threadsData.find(el => el.id === this.currentThread.id);
        let latestMsg = this.messagesData[this.messagesData.length - 1];
        currThread.latestMessage = latestMsg.content;

				this.$set(this.threadsData.find(el => el.id === this.currentThread.id), 'lastMessageCreatedAt', latestMsg.created_at);

	      this.$root.$emit('sort-threads');

	      currThread.is_read = true;
        if(this.notReadCounter.length > 0) {
          this.notReadCounter.threadsCounter.find(el => el.threadId === currThread.id).messagesNotRead = 0;
        }
      }
    },

    threadsLoaded(newVal) {
      if(newVal) {
        let thread = this.threadsData.find(el => {
          return el.targetUser.username === this.chosenUser.username;
        });
        if(thread) {
          this.openThreadRequest(thread);
        }
      }
    },
    threadsData: {
      deep: true,
	    immediate: true,
      handler(val) {
        if(val) {
          this.threadsBannedFilter(val);
          this.threadsRegularFilter(val);
          this.threadsFavouritesFilter(val);
          this.threadsAdminMessagesFilter(val);
        }
      }
    }
  },

  methods: {
    ...mapActions('chat',
    [
      'openThreadRequest', 'fetchMessageThreadsData',
      'clearCurrentThread', 'setCurrentThreadAction',
      'getNotReadCount', 'sendDontShowDeletedUsersRequest'
    ]),
    ...mapMutations('chat', ['markThreadAsSeen']),

    isCurrentThread(threadId) {
      if(this.currentThread) {
        return this.currentThread.id === threadId;
      }
      return false;
    },

    openChat(thread) {
      if( thread.userFavouritedBy) {
        this.showBlockedThreads = false;
        this.showAdminMessagesThreads = false;
      }
      router.push({
        name: 'chats',
        params:{id: thread.id},
      })
    },

    changeSeenIconInChat(e) {
      this.messagesData.slice(-e.messagesRead).filter(el => {
        if(
          (el.sender.username === this.userData.username)
          && (
            this.currentThread
            && this.currentThread.id === e.threadId
          )
        ) {
          Vue.set(el, 'seenAt', e.seenAt)
        }
      });
    },

    changeSeenIconInThreads(e) {
      this.markThreadAsSeen(e)
    },

    threadsRegularFilter(threads) {
      this.threadsRegular = threads.filter(el => el.bannedAt === null && !el.userFavouritedBy && !el.isAdminMessage);
    },

    threadsFavouritesFilter(threads) {
      this.threadsFavourites = threads.filter(el => el.userFavouritedBy);
    },

    threadsBannedFilter(threads) {
      this.threadsBanned = threads.filter(el => el.bannedAt !== null);
    },

    threadsAdminMessagesFilter(threads) {
      this.threadsAdminMessages = sortBy(threads.filter(el => el.isAdminMessage), ['is_read', 'order']);
    },

    filterThreads() {
      if(!isEmpty(this.searchThreads) && this.searchThreads.length >= 3) {
        this.isFiltering = true;
        this.filteredThreads = this.threadsData.filter((el) =>
          el.targetUser.name.toLowerCase().includes(this.searchThreads.toLowerCase())
        );
      }

      if(isEmpty(this.searchThreads)) {
        this.filteredThreads = [];
        this.isFiltering = false;
      }
    },

    hideAdminMessagesThreads() {
      this.showAdminMessagesThreads = false;
    },

    toggleBlockedThreadsAction() {
      if(this.showBlockedThreads !== true) {
        this.showRegularThreads = false;
        this.showBlockedThreads = true;
        this.showAdminMessagesThreads = false;
      } else {
        this.showRegularThreads = true;
        this.showBlockedThreads = false;
        this.showAdminMessagesThreads = false;
      }
    },

    toggleRegularThreadsAction() {
      this.showRegularThreads = this.showRegularThreads !== true;
      this.showBlockedThreads = false;
      this.showAdminMessagesThreads = false;
    },

    showRegularThreadsAction() {
      this.showRegularThreads = true;
      this.showBlockedThreads = false;
      this.showAdminMessagesThreads = false;
    },

    toggleAdminMessagesThreads() {
      if(this.showAdminMessagesThreads === true) {
        this.showRegularThreads = true;
        this.showBlockedThreads = false;
        this.showAdminMessagesThreads = false;
      } else {
        this.showRegularThreads = false;
        this.showBlockedThreads = false;
        this.showAdminMessagesThreads = true;
      }
    },
  },

  mounted() {
    const userDataId = this.userData === null ? userAuthService.getUserData().id : this.userData.id
    // Listen to changing `seen at` of a message by other user
    window.Echo.encryptedPrivate(`messages-read.${userDataId}`).listen('MessageReceiverRead', (e) => {

      this.changeSeenIconInChat(e)

      this.changeSeenIconInThreads(e)
    });

    this.$root.$on('delete-fav-action', () => {
      this.hideAdminMessagesThreads();
      if (this.threadsFavourites.length === 1) {
        this.showRegularThreadsAction();
      }
    });

    this.$root.$on('ban-action', () => {
      if (this.threadsBanned.length === 1) {
        this.showRegularThreadsAction();
      }
    });

    this.$root.$on('unfav-action', () => {
      this.hideAdminMessagesThreads();
      if (this.threadsFavourites.length === 1) {
        this.showRegularThreadsAction();
      }
    });

  },
  beforeDestroy() {
    if(this.currentThread) {
      this.clearCurrentThread();
    }
  },
  setup() {
    return {
      // Icons
      icons: {
        mdiCheck,
        mdiCheckAll,
      },
    }
  }
}
</script>

<style lang="scss">
  .chat-borders {
    border-right: 0 !important;
    border-left: 0 !important;
  }
</style>