<template>
	<div>
		<div class="flex flex-col items-center justify-between w-full m-0 mt-auto bg-white relative rounded"
		     @click="(currentThread.targetUser.isBanned || currentThread.bannedAt || userData.isBanned) ? openSendMessageModalFail(true) : null"
		>
			<div class="w-full">
				<VueTrix v-model="messageContent" type="text" ref="messageInput" id="messageInput"
          :placeholder="editorDisabledProp ? '' : currentThread.bannedAt
            ? trans('PLACEHOLDER:ChatFormUnlockToChat')
            : trans('PLACEHOLDER:ChatForm')"
          inputName="msgContent"
          class="block w-full lg:w-full outline-none focus:text-gray-700 border-none messages-chat px-3 lg:px-6 pt-4 pb-0 "
          name="message" required autocomplete="off" :disabledEditor="disableEditor || currentThread.targetUser.isBanned"
				/>
			</div>
			<div class="flex w-full justify-between px-3 lg:px-6 bg-white pt-2 pb-3 lg:pb-4 items-center rounded"
			>
				<!--	 Container to block buttons   -->
				<div class="absolute w-full h-24 z-10" v-if="currentThread.targetUser.isBanned || currentThread.bannedAt !== null || userData.isBanned">

				</div>
				<div class="flex flex-row gap-6 items-center">
					<VDropdown placement="top">
						<t-button variant="icon">
							<VTooltip>
								<base-icon :height="25" :width="25" name="palette">
								</base-icon>
								<template #popper>
									{{ trans('TOOLTIP:ChatColor') }}
								</template>
							</VTooltip>
						</t-button>
						<template #popper>
							<ChatColorsPalette :current-color="currentThread.color" />
						</template>
					</VDropdown>
					<slot name="onEnterSend"></slot>
				</div>
				<div class="flex flex-row gap-6 items-center">
					<emoji-picker v-if="!isMobile" @emoji="insert" :search="search">
						<div slot="emoji-invoker"
						     slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent"
						>
							<VTooltip>
								<base-icon :height="25" :width="25" name="smile" :class="{
									  'cursor-pointer hover:text-redAccentLight hover:opacity-70': !!!currentThread.bannedAt && !editorDisabledProp && !disableEditor,
									  'opacity-70': currentThread.bannedAt || editorDisabledProp
									}"
								>
								</base-icon>
								<template #popper>
									{{ trans('TOOLTIP:Emojis') }}
								</template>
							</VTooltip>
						</div>
						<div v-if="!!!currentThread.bannedAt && !editorDisabledProp && !disableEditor" slot="emoji-picker" slot-scope="{ emojis, insert, display }"
						     class="emoji-picker"
						>
							<div>
								<div>
									<div v-for="(emojiGroup, category) in emojis" :key="category">
										<div class="grid grid-cols-10 gap-2 items-center justify-between z-50"
										     v-if="category !== 'Frequently used'"
										>
                      <span
		                      class="w-6 h-6 text-2xl text-center"
		                      v-for="(emoji, emojiName) in emojiGroup"
		                      :key="emojiName"
		                      @click="currentThread.bannedAt ? null : insert(emoji)"
		                      :title="emojiName"
                      >
                        {{ emoji }}
                      </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</emoji-picker>
					<Button
							:variant="isButtonDisabled() ? 'iconDisabled' : 'icon'"
							:disabled="isButtonDisabled() || emptyMessage" @keyup.enter="this.onEnterSend ? sendMessageAction : ''"
							@clicked="sendMessageAction"
					>
						<VTooltip>
							<base-icon :height="25" :width="25" name="sendMessage">
							</base-icon>
							<template #popper>
								<template v-if="userData.isBanned">
									{{ trans('MESSAGE:ChatIsBlockedBecauseOfBan1') + ' ' + trans('MESSAGE:ChatIsBlockedBecauseOfBan2') }}
								</template>
								<template v-else>
									{{ trans('TOOLTIP:SendMessage') }}
								</template>
							</template>
						</VTooltip>
					</Button>
				</div>
			</div>
		</div>
		<FailedActionModal v-if="showSendMessageFailModal" :show-modal="showSendMessageFailModal" @modal-clicked="openSendMessageModalFail(false)"/>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapState, mapMutations} from 'vuex';
import emojis from '@/assets/emojis/emojis'
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import SwitchCheckbox from "@/utils/crud/components/field-types/Switch";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import {isEmpty, isEmptyChatMessage, removeBrTags, stripTags} from "@/common/helpers/utils";
import ChatColorsPalette from "@/views/content/messages/components/ChatColorsPalette";
import Modal from "@/utils/modal/Modal.vue";
import FailedActionModal from "@/views/content/components/Modals/FailedActionModal.vue";
import dateFormat, { masks } from "dateformat";

export default {
  name: "ChatForm",
  components: {FailedActionModal, Modal, ChatColorsPalette, SwitchCheckbox, BaseIcon, Button},
  props: {
    onEnterSend: Boolean,
    editorDisabledProp: Boolean,
  },
  data() {
    return {
      messageContent: '',
      search: '',
      test: '',
      emojiInserted: false,
      emptyMessage: false,
      showSendMessageFailModal: false,
    }
  },
  watch: {
    messagesData() {
      this.$nextTick(() => {
        this.messageContent = '';
      })
    },
    messageSent(newVal) {
      if(newVal) {
        if(!this.chatErrors) {
          // Mark latest message as not read in thread view
          this.makeLastThreadOwnerMessageUnread(this.currentThread.id)
        }
      }
    },
    messageContent(newVal) {
      let trixEditor = this.$refs['messageInput'].$refs.trix.editor;
      if(newVal.includes(':)')) {
        this.messageContent = this.replaceEmoji(':)', emojis['blush']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(';)')) {
        this.messageContent = this.replaceEmoji(';)', emojis['wink']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(':D')) {
        this.messageContent = this.replaceEmoji(':D', emojis['smile']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(':P')) {
        this.messageContent = this.replaceEmoji(':P', emojis['stuck_out_tongue']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(':*')) {
        this.messageContent = this.replaceEmoji(':*', emojis['kissing_heart']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(':/') && !newVal.includes('http')) {
        this.messageContent = this.replaceEmoji(':/', emojis['confused']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(':\')')) {
        this.messageContent = this.replaceEmoji(':\')', emojis['joy']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(';(')) {
        this.messageContent = this.replaceEmoji(';(', emojis['sob']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(':@')) {
        this.messageContent = this.replaceEmoji(':@', emojis['angry']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(':(')) {
        this.messageContent = this.replaceEmoji(':(', emojis['disappointed']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(':o')) {
        this.messageContent = this.replaceEmoji(':o', emojis['hushed']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }
      if(newVal.includes(':|')) {
        this.messageContent = this.replaceEmoji(':|', emojis['neutral_face']);
        trixEditor.loadHTML(this.messageContent);
        this.updateCursor();
      }

      this.setCurrentMessageOnChat({
        threadId: this.currentThread.id,
        content: newVal,
      })
    }
  },
  computed: {
    ...mapGetters('chat', ['messagesData', 'threadsData', 'chosenUser', 'currentThread', 'threadsLoaded', 'banLoading', 'chatErrors', 'messageSent', 'currentMessage']),
    ...mapGetters('account', ['userData']),
    ...mapState('chat', {
      messagesLoaded: state => state.loadedMessages,
      threadsLoaded: state => state.loadedThreads,
    }),
    blockChat() {
      if((this.currentThread.newThread && this.messagesData.length >= 1 && this.messagesData[this.messagesData.length - 1].sender.username === this.userData.username)) {
        return true;
      }
    },
    isMobile() {
      return checkIsMobileDevice();
    },
	  disableEditor() {
			return this.blockChat || !!this.currentThread.bannedAt || this.editorDisabledProp
	  }
  },
  methods: {
    ...mapActions('chat', ['openThreadRequest', 'fetchMessageThreadsData', 'sendMessageRequest', 'setCurrentThreadAction', 'clearCurrentThread', 'changeReadRequest', 'sendNewContactMessageRequest', 'banThreadRequest']),
    ...mapMutations('chat', ['setIsCurrentThreadNew', 'setInputState', 'makeLastThreadOwnerMessageUnread', 'setCurrentMessageOnChat', 'setMessagesData']),
    ...mapActions('alert', ['error']),

    updateChat(data) {
      let messages = structuredClone(this.messagesData);

			let createdAtDate = new Date(data.created_at);
      messages.push({
        content: data.messageContent,
        created_at: data.created_at,
        created_at_date: createdAtDate.toISOString().substring(0, 10),
        sender: data.sender,
        receiver: data.receiver,
        owner: data.owner,
	      tempId: data.tempId ?? null,
	      sendingFailed: false,
      });

      this.setMessagesData({messages: messages});
    },

    sendMessageAction() {
      if(!this.userData.isVerified || !this.userData.isSubscriptionActive || this.currentThread.targetUser.isDeleted || this.currentThread.targetUser.isBanned || this.currentThread.bannedAt) {
        this.openSendMessageModalFail(true);
        return;
      }

	    if(isEmptyChatMessage(this.currentMessage.message)) {
	      this.openSendMessageModalFail(true);
        return;
      }
      if(this.messagesData.length > 1) {
        this.setIsCurrentThreadNew(false);
      }

			let msgContent = removeBrTags(this.messageContent);

			let tempId = Math.ceil(Math.random()*1000000);
	    this.addMsgToChat(tempId);
	    this.cleanChatForm();

      this.sendMessageRequest({
        content: msgContent,
        receiverUsername: this.currentThread.targetUser.username,
	      tempId: tempId
      });

			this.$emit('scroll-chat');
    },
    updateCursor() {
      let trixEditor = this.$refs['messageInput'].$refs.trix.editor;
      this.$nextTick(() => {
        trixEditor.setSelectedRange(trixEditor.getDocument().toString().length - 1)
      })
    },
    inputFocus() {
      if(!this.currentThread.newThread && !this.currentThread.is_read) {
        this.changeReadRequest(this.currentThread);
      }
      this.setInputState(true)
    },

    inputBlur() {
      this.setInputState(false)
    },

    insert(emoji) {
      this.messageContent += emoji
    },

    replaceEmoji(str, emoji) {
      return this.messageContent.replace(str, emoji);
    },

    openSendMessageModalFail(value) {
      this.showSendMessageFailModal = value;
    },

		isButtonDisabled() {
			return this.currentThread.bannedAt || this.userData.isBanned;
		},

	  addMsgToChat(tempId = null) {
		  let nowDate = new Date();
		  let msgContent = removeBrTags(this.messageContent);
		  this.updateChat({
			  messageContent: msgContent,
			  created_at: dateFormat(nowDate, 'yyyy-mm-dd HH:MM:ss'),
			  sender: this.userData,
			  receiver: this.currentThread.targetUser,
			  owner: this.currentThread.ownerUser,
			  tempId: tempId,
		  });
	  },

	  cleanChatForm() {
		  this.messageContent = '';
		  this.$refs['messageInput'].$el.querySelector('.trix-content').innerHTML = this.messageContent;
	  }

  },
  mounted() {
    const self = this;
    if(this.currentMessage.threadId === this.currentThread.id) {
      this.messageContent = this.currentMessage.message;
      this.$nextTick(() => {
        let trixEditor = this.$refs['messageInput'].$refs.trix.editor;
        setTimeout(() => {
          trixEditor.setSelectedRange(trixEditor.getDocument().toString().length - 1)
        }, 1)
      })
    }
    if(this.onEnterSend) {

      this.$refs['messageInput'].$el.addEventListener('keydown', function(e) {
        if (e.key === "Enter") {
              e.preventDefault()
              self.sendMessageAction();
            }
      })
    }

    this.$refs['messageInput'].$el.addEventListener('trix-focus', function(e) {
        self.inputFocus();
    })
    this.$refs['messageInput'].$el.addEventListener('trix-blur', function(e) {
      self.inputBlur();
    })
  }
}
</script>

<style lang="scss">
.trix-content {
  height: 90px;
  max-height: 150px !important;
  overflow-y: auto !important;
}

@media (max-width: 1024px) {
	#messageInput {
		height: 80px !important;
	}

  .trix-content
  {
	  height: 60px !important;
	  min-height: 60px !important;
    max-height: 60px !important;
    overflow-y: auto !important;
  }

	.emoji-picker {
		top: -18px !important;
	}

	trix-toolbar {
		display: none;
	}

}

.emoji-picker {
  position: absolute;
  top: -31px;
	right: 0;
  z-index: 999;
  border: 1px solid #ddd;
  height: 178px;
  overflow-y: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;

}

.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 1rem;
  cursor: default;
}
.emoji-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker span {
  cursor: pointer;
}
.emoji-picker span:hover {
  background: transparent;
  cursor: pointer;
  scale:125%;
}

.messages-chat {
  .trix-content {
    background: theme('colors.grayedLight') !important;
    border:none;
    border-radius: 6px;
    word-break: break-word;
  }
}

</style>