export const chatColors = [
    {
        name: 'red',
        text: 'text-black',
        color: 'bg-red-500',
    },
    {
        name: 'orange',
        text: 'text-black',
        color: 'bg-orange-500',
    },
    {
        name: 'amber',
        text: 'text-black',
        color: 'bg-amber-500',
    },
    {
        name: 'yellow',
        text: 'text-black',
        color: 'bg-yellow-500',
    },
    {
        name: 'lime',
        text: 'text-black',
        color: 'bg-lime-500',
    },
    {
        name: 'green',
        text: 'text-black',
        color: 'bg-green-500',
    },
    {
        name: 'emerald',
        text: 'text-white',
        color: 'bg-emerald-500',
    },
    {
        name: 'teal',
        text: 'text-white',
        color: 'bg-teal-500',
    },
    {
        name: 'cyan',
        text: 'text-black',
        color: 'bg-cyan-500',
    },
    {
        name: 'sky',
        text: 'text-black',
        color: 'bg-sky-500',
    },
    {
        name: 'blue',
        text: 'text-white',
        color: 'bg-blue-500',
    },
    {
        name: 'indigo',
        text: 'text-white',
        color: 'bg-indigo-500',
    },
    {
        name: 'violet',
        text: 'text-white',
        color: 'bg-violet-500',
    },
    {
        name: 'purple',
        text: 'text-white',
        color: 'bg-purple-500',
    },
    {
        name: 'fuchsia',
        text: 'text-white',
        color: 'bg-fuchsia-500',
    },
    {
        name: 'pink',
        text: 'text-white',
        color: 'bg-pink-500',
    },
    {
        name: 'grayed',
        text: 'text-black',
        color: 'bg-grayed',
    },
    {
        name: 'grayedDark',
        text: 'text-white',
        color: 'bg-grayedDark',
    },
    {
        name: 'black',
        text: 'text-white',
        color: 'bg-black',
    },
    {
        name: 'redAccent',
        text: 'text-white',
        color: 'bg-redAccent',
    },
];
