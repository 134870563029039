<template>
  <PageContainer>
    <PageTitle v-if="!isMobile || isMobile && !currentThread">
      <div>
        {{ trans('PAGE_TITLE:Chats') }}
      </div>
      <BadgeFrame v-if="userData && userData.isBanned">
        <Badge tagName="span" variant="badgeError">
          {{ trans('LABEL:AccountBlocked') }}
        </Badge>
      </BadgeFrame>
    </PageTitle>
    <PageContentWithSidebar>
      <!-- Sidebar -->
      <Sidebar
         :class="{ 'hidden': chatActiveMobile}"
         v-show="isMobile ? !messagesLoading && !threadsLoading : true"
      >
        <Loader
          v-if="threadsLoading && !isMobile"
          class="w-sidebar h-088screen"
        />
        <Threads
          v-else
          :user-data="userData"
        />
      </Sidebar>
      <!-- Page content -->
      <Loader
        v-if="messagesLoading || threadsLoading"
        class="w-full lg:w-main-container lg:h-088screen"
        :class="getHeightOfScreenWidth(1)"
      />
      <template v-else >
        <template v-if="currentThread">
          <template v-if="!userData.isSuperAdmin && (currentThread && currentThread.isAdminMessage)">
            <ChatAdmin class="w-full lg:w-main-container h-088screen" :key="currentThread.id" />
          </template>
          <template v-else>
            <Chat class="w-full lg:w-main-container h-088screen" :class="{'h-098screen': isChatOpenOnMobile()}" :key="currentThread.id" />
          </template>
        </template>
        <template v-else v-show="!isMobile">
          <t-card class="w-full lg:w-main-container h-088screen flex flex-col" v-show="!isMobile">
            <div class="w-full flex flex-col items-center text-center gap-3">
              <template v-if="userData && userData.hasFullAccess">
                <base-icon :width="120" :height="120" class="mb-3" name="chats" color="fill-grayedLight"/>
                <template v-if="threadsData.length === 0">
                  <Hint variant="Success" :large="true" class="w-min whitespace-nowrap">
                    {{ trans('MESSAGE:AddNewContactToStartChat1') }} <br/> {{ trans('MESSAGE:AddNewContactToStartChat2') }}
                  </Hint>
                  <Button
                    variant="buttonRed"
                    icon="newContactsFilled"
                    class="mt-3"
                    :to="{name: 'search'}"
                    :text="trans('BUTTON:NewContacts')"
                  />
                </template>
                <template v-else>
                  <Hint variant="Success" :large="true" class="w-min whitespace-nowrap">
                    {{ trans('MESSAGE:ChoosePersonToStartChat') }}
                  </Hint>
                </template>
              </template>
              <template v-else>
                <base-icon name="chats" :width="120" :height="120" color="fill-grayedLight"/>
                <div class="w-3/4 grid justify-items-center leading-normal gap-3 mt-3">
                  <template v-if="userData && userData.isBanned">
                    <Hint variant="Error" :large="true" class="w-min whitespace-nowrap">
                      {{ trans('MESSAGE:ChatIsBlockedBecauseOfBan1') }}<br/>{{ trans('MESSAGE:ChatIsBlockedBecauseOfBan2') }}
                    </Hint>
                  </template>
                  <template v-else>
                    <Hint variant="Error" :large="true" class="w-min whitespace-nowrap">
                      {{ trans('MESSAGE:BuySubscriptionToUnlockChats1') }}<br/>{{ trans('MESSAGE:BuySubscriptionToUnlockChats2') }}
                    </Hint>
                      <Button
                        variant="buttonRed"
                        class="w-min"
                        :to="{name: 'subscription'}"
                        :text="trans('BUTTON:BuySubscription')"
                      />
                  </template>
                </div>
              </template>
            </div>
          </t-card>
        </template>
      </template>
    </PageContentWithSidebar>
  </PageContainer>

</template>

<script>
import Threads from "@/views/content/messages/Threads";
import Chat from "@/views/content/messages/Chat";
import {mapActions, mapGetters} from 'vuex';
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import PageContainer from "@/layouts/components/pages/PageContainer";
import Loader from "@/views/Loader";
import PageTitle from "@/layouts/components/pages/PageTitle";
import PageContentWithSidebar from "@/layouts/components/pages/PageContentWithSidebar"
import Sidebar from "@/layouts/components/pages/Sidebar"
import {isEmpty, getHeightOfScreenWidth, isChatOpenOnMobile} from "@/common/helpers/utils";
import router from "@/router";
import Badge from "@/utils/crud/components/Badge";
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import ChatAdmin from "@/views/content/messages/ChatAdmin";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Hint from "@/views/content/components/Hint";

export default {
name: "Messages",
  components: {
    BadgeFrame,
    ChatAdmin,
    Badge,
    BaseIcon,
    Button,
    PageTitle,
    Loader,
    Chat,
    Threads,
    PageContainer,
    Hint,
    PageContentWithSidebar,
    Sidebar
  },
  data() {
    return {
      isMobile: checkIsMobileDevice(),
      areThreadsLoaded: false,
      currentThreadId: null,
      allowOpenThread: false,
      initialLoadingThreads: false,
      getHeightOfScreenWidth: getHeightOfScreenWidth,
    }
  },
  watch: {
    langLocale() {
      this.fetchMessageThreadsData();
    },
    '$route.params.id': {
      immediate: true,
      handler(val) {
        if(val && this.allowOpenThread) {
          this.currentThreadId = val;
          this.openThreadRequest(this.threadsData.find(el => el.id === Number(this.currentThreadId)));
        }
      }
    },
    allowOpenThread(val) {
      if(val && this.$route.params.id) {
        const thread = this.threadsData.find(el => el.id === Number(this.$route.params.id));
        if(isEmpty(thread)) {
          router.push({name: 'chats'});
          return;
        }
        this.openThreadRequest(thread);
      }
    },
    threadsLoaded(val) {
      if(val) {
        this.allowOpenThread = true;
      }
    },
    dontShowDeletedUsersDone(val) {
      if(val) {
        this.fetchMessageThreadsData();
				if(this.$route.params.id) {
					router.push({name: 'chats'})
				}
      }
    }
  },
  computed: {
    ...mapGetters('chat', ['currentThread', 'threadsData', 'chatActiveMobile', 'messagesLoading', 'threadsLoaded', 'threadsLoading', 'dontShowDeletedUsersDone']),
    ...mapGetters('account', ['userData']),
    ...mapGetters('lang', ['langLocale']),
  },
  methods: {
	  isChatOpenOnMobile,
    ...mapActions('chat', ['fetchMessageThreadsData', 'openThreadRequest']),
    ...mapActions('favourites', ['fetchFavouriteUsers']),
  },
  mounted() {
    this.fetchMessageThreadsData();
    this.fetchFavouriteUsers();
  },
}
</script>

