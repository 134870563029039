<template>
  <div class="flex flex-col flex-auto bg-white border-t border-b lg:rounded lg:border border-grayedHR lg:shadow-lg">
    <div class="px-3 lg:px-6 pt-3 lg:pt-4 pb-3 flex flex-row flex-none justify-between items-center z-index-2 border-b border-dashed border-grayedDark"
      v-if="currentThread"
    >
      <div class="flex flex-row justify-between">
        <Button v-if="isMobile"
          class="mr-2"
          variant="icon"
          @clicked="closeThread"
        >
          <base-icon :height="16" :width="16" name="chevronLeft">
          </base-icon>
        </Button>
        <div class="w-full flex flex-row gap-2 lg:gap-3 group items-center">
          <Picture
            is-chat
            :src="systemAvatar"
            classes="object-cover w-7 h-7 rounded-full mr-2 lg:block hidden border border-black"
          />
          <div class="block font-semibold text-black">
            {{ trans('LABEL:AdministratorName') }}
          </div>
        </div>
      </div>
    </div>
    <!--  MESSAGE CHAT SECTION  -->
    <div class="relative px-3 lg:px-6 py-4 flex-1 overflow-y-scroll"
      ref="chatContainer"
      :class="{'border-b border-dashed border-grayedDark': userData.isAdmin}"
    >
      <div class="absolute left-1/2 transform -translate-x-1/2"
        v-show="messagesPagination.last_page > 1
          && messagesPagination.current_page !== messagesPagination.last_page"
      >
        <Button variant="link"
          class="text-s inline-flex gap-2 items-center pr-3"
          @clicked="loadMoreMessages"
          :loading="newMessagesLoading"
        >
          <base-icon name="cloudDownloadFilled"></base-icon>
          {{ trans('BUTTON:LoadMore') }}
        </Button>
      </div>
      <ul class="space-y-0">
        <li class="flex flex-col justify-start message-box"
          v-for="(message, index) in messagesData"
          :key="index"
          :class="{
            'justify-end' : checkIsSender(message.sender.username),
            'border-b border-white':isNextFromTheSameSender(message, index),
            'mt-8': messagesPagination.last_page > 1
               && messagesPagination.current_page !== messagesPagination.last_page
          }"
        >
          <template v-if="(isPreviousFromYesterday(message,index) || index === 0)
            && !checkIsSystemMessage(message.sender)"
          >
            <div class="flex flex-row w-full items-center justify-center text-xs text-grayedDark py-3">
              {{ message.created_at_date }}
            </div>
          </template>
          <div class="flex flex-row  items-start"
            :class="{
              'justify-end':checkIsSender(message.sender.username),
              'mb-3':!isNextFromTheSameSender(message, index) && index !== messagesData.length-1,
              'mb-1':isNextFromTheSameSender(message, index) && isNextSeparated(message, index)
            }"
          >
            <div class="flex-none">
              <template v-if="!checkIsSender(message.sender.username) && message.showImage && message.sender !== systemSender">
                <Picture
                  is-chat
                  :src="systemAvatar"
                  classes="object-cover w-7 h-7 rounded-full mr-2 lg:block hidden border border-black"
                  :class="{'img-gray': currentThread.bannedAt}"
                />
              </template>
              <template v-if="!checkIsSender(message.sender.username)
                && !message.showImage && message.sender !== systemSender"
              >
                <div class="w-6 mr-3 lg:block hidden">
                </div>
              </template>
              <template v-if="checkIsSystemMessage(message.sender)">
                <img
                  :src="systemAvatar"
                  alt="app logo"
                  class="object-cover border border-black rounded-full mr-3"
                />
              </template>
            </div>
            <div class="relative lg:max-w-md max-w-xs px-3 py-2 rounded-xl"
              :class="[
                {
                  'bg-white border border-grayedLight': !checkIsSender(message.sender.username),
                  // Wlasne wiadomosci
                  'bg-grayedLight text-black border border-grayedHR ml-12 lg:ml-0' : checkIsSender(message.sender.username),
                  'rounded-tr-sm':isPreviousFromTheSameSender(message, index) && checkIsSender(message.sender.username)
                    && !isPreviousSeparated(message, index),
                  'rounded-br-sm':isNextFromTheSameSender(message, index) && checkIsSender(message.sender.username)
                    && !isNextSeparated(message, index),
                  // Cudze wiadomosci
                  'mr-6 lg:mr-0 rounded-tl-sm':isPreviousFromTheSameSender(message, index) && !checkIsSender(message.sender.username)
                    && !isPreviousSeparated(message,index),
                  'rounded-bl-sm':isNextFromTheSameSender(message, index) && !checkIsSender(message.sender.username)
                    && !isNextSeparated(message,index),
                  'pt-1':isPreviousFromTheSameSender(message, index) && !isPreviousSeparated(message, index),
                  'pb-1':isNextFromTheSameSender(message, index) && !isNextSeparated(message, index),
                }
              ]"
            >
              <span :ref="index === messagesData.length - 1 ? 'lastMessage' : ''"
                class="block break-words text-sm leading-normal"
                v-html="message.content"
              >
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!--  END MESSAGE CHAT SECTION  -->

    <!--  TRIX AND BOTTOM SETTINGS SECTION  -->
    <div class="flex-none z-index-2 flex-1 row-span-4 row-start-8 row-end-13 messages-chat"
      v-if="currentThread && userData.isSuperAdmin"
      ref="messageForm"
    >
      <ChatForm
        :onEnterSend="!!onEnterSend"
        :key="onEnterSend"
        @scroll-chat="scrollToBottomPosition = true"
        :editor-disabled-prop="currentThread.systemMessage !== null"
      >
        <template v-slot:onEnterSend>
          <CheckboxField
            v-model="onEnterSend"
            class="hover:opacity-70 hover:text-redAccentLight"
            :label="trans('LABEL:SendOnEnter')"
          />
        </template>
      </ChatForm>
    </div>
    <!--  END TRIX AND BOTTOM SETTINGS SECTION  -->
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import { mdiCheck, mdiCheckAll, mdiKeyboardReturn } from '@mdi/js'
import ChatForm from "@/views/content/messages/ChatForm";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import CheckboxField from "@/utils/crud/components/field-types/Checkbox";
import themeConfig from "@/themeConfig";
import Modal from "@/utils/modal/Modal";
import ThreadActions from "@/views/content/messages/components/ThreadActions";
import router from "@/router";
import {chatColors} from "@/config/chatColors";

export default {
  name: "ChatAdmin",
  components: {
    CheckboxField,
    BaseIcon,
    Button,
    ChatForm,
    ThreadActions,
    Modal
  },
  data() {
    return {
      messageContent: '',
      search: '',
      isMobile: checkIsMobileDevice(),
      systemSender: 'SYSTEM',
      systemAvatar: themeConfig.app.logo,
      isNewContact: false,
      hasScrolledToTop: false,
      showLoadMoreButton: false,
      currentPage: 1,
      intervalToSeparateMessages:30*1000,
      chatColors: chatColors,
      scrollToBottomPosition: false,
    }
  },

  computed: {
    ...mapGetters('chat',
    [
        'messagesData', 'messagesLoaded',
        'chatActiveMobile', 'chosenUser', 'currentThread',
        'threadsData', 'threadsLoaded', 'banLoading', 'chatErrors', 'messagesPagination',
        'newMessagesLoading'
    ]),
    ...mapGetters('account', ['userData']),

    onEnterSend: {
      get() {
        if(this.$store.getters['chat/onEnterSend'] === null) {
          return this.userData.onEnterSend
        }
        return this.$store.getters['chat/onEnterSend'];
      },
      set(value) {
        this.sendOnEnterSendRequest({onEnterSend: value})
      }
    },
    chatColor () {
      return this.currentThread.color.text + ' ' + this.currentThread.color.color;
    }
  },
  watch: {
    messagesLoaded: {
      immediate: true,
      handler(val) {
        if(val) {
          this.$nextTick(() => {
            this.scrollToBottom()
          })
        }
      }
    },
    messagesData() {
      let initialHeight = this.$refs.chatContainer.scrollHeight
      let vm = this
      this.$nextTick(() => {
        if(this.messagesPagination.current_page === 1 || this.scrollToBottomPosition) {
          this.scrollToBottom();
        } else {
          this.scrollToNewPosition(vm.$refs.chatContainer.scrollHeight, initialHeight);
        }
      })
    },
    'currentThread.systemMessage': {
      immediate: true,
      deep: true,
      handler(val) {
        if(val !== null) {
          this.addSystemMessage(val);
        }
      }
    },
    hasScrolledToTop(val) {
      setTimeout(() => {
        this.showLoadMoreButton = val;
      }, 300);
    }
  },
  methods: {
    ...mapActions('chat', [
        'openThreadRequest', 'fetchMessageThreadsData', 'sendMessageRequest',
        'setCurrentThreadAction', 'clearCurrentThread', 'changeReadRequest', 'sendNewContactMessageRequest',
        'banThreadRequest', 'sendOnEnterSendRequest', 'loadMoreMessagesRequest'
    ]),
    ...mapActions('favourites', ['addFavouriteProfile', 'removeFavouriteProfile']),
    ...mapMutations('chat', ['setInputState', 'setIsCurrentThreadNew', 'setOnEnterSend', 'setMessagesData']),
    isNextFromTheSameSender(message, index) {
      if (index < this.messagesData.length-1) {
        return this.messagesData[index+1].sender.username === message.sender.username;
      }
      return false;
    },
    isNextSeparated(message, index) {
      if (index < this.messagesData.length-1) {
        let date1 = new Date(message.created_at);
        let date2 = new Date(this.messagesData[index+1].created_at);
        return date2 - date1 > this.intervalToSeparateMessages;
      }
      return false;
    },
    isPreviousFromTheSameSender(message, index) {
      if (index > 0) {
        return this.messagesData[index-1].sender.username === message.sender.username;
      }
      return false;
    },
    isPreviousFromYesterday(message, index) {
      if (index > 0) {
        let date1 = new Date(this.messagesData[index-1].created_at);
        let date2 = new Date(message.created_at);

        return date2.getDay() !== date1.getDay();
      }
      return false;
    },
    isPreviousSeparated(message, index) {
      if (index > 0) {
        let date1 = new Date(this.messagesData[index-1].created_at);
        let date2 = new Date(message.created_at);
        return date2 - date1 > this.intervalToSeparateMessages;
      }
      return false;
    },
    checkIsSender(senderUsername) {
      return senderUsername === this.userData.username;
    },
    checkIsSystemMessage(sender) {
      return sender === this.systemSender;
    },
    scrollToBottom() {
      if(this.$refs.chatContainer !== undefined) {
        this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight
      }
    },
    scrollToNewPosition(scrollHeight, initial) {
      this.$refs.chatContainer.scrollTo(0, scrollHeight - initial )
    },
    goToProfile(username) {
      router.push({name: 'profile', params: {username: username}})
    },
    updateChat(data) {
      let messages = structuredClone(this.messagesData)
      messages.push({
        content: data.messageContent,
        created_at: data.created_at,
        created_at_date: data.created_at_date,
        sender: data.sender,
        receiver: data.receiver,
        owner: data.owner,
      });

      this.messageContent = null;

      this.setMessagesData({messages: messages});

      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },

    closeThread() {
      router.push({name: 'chats'});
      this.clearCurrentThread();
    },

    addSystemMessage(msg) {
      let messages = structuredClone(this.messagesData)
      messages.push({
        content: msg,
        sender: this.systemSender,
        receiver: this.userData,
        owner: null,
      });

      this.setMessagesData({messages: messages});
    },

    getChatColor(message) {
      return !this.checkIsSender(message.sender.username) && !this.checkIsSystemMessage(message.sender);
    },

    handleScrollTop(el) {
      this.hasScrolledToTop = el.target.scrollTop === 0;
    },

    loadMoreMessages() {
      let currPage = this.messagesPagination.current_page + 1;
      this.scrollToBottomPosition = false;
      this.loadMoreMessagesRequest({id: this.currentThread.id, page: currPage});
    }

  },

  mounted() {
    this.setOnEnterSend(this.onEnterSend);
    if(this.currentThread && (!this.currentThread.newThread || this.messagesData.length > 0)) {
      // Listen to new message if thread is chosen
      window.Echo.encryptedPrivate(`chat.${this.currentThread.id}`).listen('MessageSent', (e) => {
        if(e.thread_id === this.currentThread?.id){
          this.updateChat({
            messageContent: e.content,
            created_at: e.created_at,
            created_at_date: e.created_at_date,
            sender: this.currentThread.targetUser,
            receiver: this.userData,
            owner: this.currentThread.ownerUser,
          });

          this.changeReadRequest(this.currentThread);

          if(!e.newThread) {
            this.setCurrentThreadProperty({property: 'systemMessage', value: null});
            let adminMessageIndex = this.messagesData.findIndex(el => el.owner === null);
            if(adminMessageIndex >= 0) {
              this.messagesData.splice(adminMessageIndex, 1);
            }
          }
        }
        if(this.currentThread?.newThread) {
          this.currentThread.newThread = false;
        }
      })
    }
  },

  setup() {
    return {
      // Icons
      icons: {
        mdiCheck,
        mdiCheckAll,
        mdiKeyboardReturn
      },
    }
  }

}
</script>

<style lang="scss">

</style>