<template>
  <div class="w-full flex flex-col gap-3 lg:flex-row lg:gap-6 lg:justify-between">
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: "PageContentWithSidebar"
}
</script>
