var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-auto bg-white border-t border-b lg:rounded lg:border border-grayedHR lg:shadow-lg"},[(_vm.currentThread)?_c('div',{staticClass:"px-3 lg:px-6 pt-3 lg:pt-4 pb-3 flex flex-row flex-none justify-between items-center z-index-2 border-b border-dashed border-grayedDark"},[_c('div',{staticClass:"flex flex-row justify-between"},[(_vm.isMobile)?_c('Button',{staticClass:"mr-2",attrs:{"variant":"icon"},on:{"clicked":_vm.closeThread}},[_c('base-icon',{attrs:{"height":16,"width":16,"name":"chevronLeft"}})],1):_vm._e(),_c('div',{staticClass:"w-full flex flex-row gap-2 lg:gap-3 group items-center"},[_c('Picture',{attrs:{"is-chat":"","src":_vm.systemAvatar,"classes":"object-cover w-7 h-7 rounded-full mr-2 lg:block hidden border border-black"}}),_c('div',{staticClass:"block font-semibold text-black"},[_vm._v(" "+_vm._s(_vm.trans('LABEL:AdministratorName'))+" ")])],1)],1)]):_vm._e(),_c('div',{ref:"chatContainer",staticClass:"relative px-3 lg:px-6 py-4 flex-1 overflow-y-scroll",class:{'border-b border-dashed border-grayedDark': _vm.userData.isAdmin}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messagesPagination.last_page > 1
        && _vm.messagesPagination.current_page !== _vm.messagesPagination.last_page),expression:"messagesPagination.last_page > 1\n        && messagesPagination.current_page !== messagesPagination.last_page"}],staticClass:"absolute left-1/2 transform -translate-x-1/2"},[_c('Button',{staticClass:"text-s inline-flex gap-2 items-center pr-3",attrs:{"variant":"link","loading":_vm.newMessagesLoading},on:{"clicked":_vm.loadMoreMessages}},[_c('base-icon',{attrs:{"name":"cloudDownloadFilled"}}),_vm._v(" "+_vm._s(_vm.trans('BUTTON:LoadMore'))+" ")],1)],1),_c('ul',{staticClass:"space-y-0"},_vm._l((_vm.messagesData),function(message,index){return _c('li',{key:index,staticClass:"flex flex-col justify-start message-box",class:{
          'justify-end' : _vm.checkIsSender(message.sender.username),
          'border-b border-white':_vm.isNextFromTheSameSender(message, index),
          'mt-8': _vm.messagesPagination.last_page > 1
             && _vm.messagesPagination.current_page !== _vm.messagesPagination.last_page
        }},[((_vm.isPreviousFromYesterday(message,index) || index === 0)
          && !_vm.checkIsSystemMessage(message.sender))?[_c('div',{staticClass:"flex flex-row w-full items-center justify-center text-xs text-grayedDark py-3"},[_vm._v(" "+_vm._s(message.created_at_date)+" ")])]:_vm._e(),_c('div',{staticClass:"flex flex-row  items-start",class:{
            'justify-end':_vm.checkIsSender(message.sender.username),
            'mb-3':!_vm.isNextFromTheSameSender(message, index) && index !== _vm.messagesData.length-1,
            'mb-1':_vm.isNextFromTheSameSender(message, index) && _vm.isNextSeparated(message, index)
          }},[_c('div',{staticClass:"flex-none"},[(!_vm.checkIsSender(message.sender.username) && message.showImage && message.sender !== _vm.systemSender)?[_c('Picture',{class:{'img-gray': _vm.currentThread.bannedAt},attrs:{"is-chat":"","src":_vm.systemAvatar,"classes":"object-cover w-7 h-7 rounded-full mr-2 lg:block hidden border border-black"}})]:_vm._e(),(!_vm.checkIsSender(message.sender.username)
              && !message.showImage && message.sender !== _vm.systemSender)?[_c('div',{staticClass:"w-6 mr-3 lg:block hidden"})]:_vm._e(),(_vm.checkIsSystemMessage(message.sender))?[_c('img',{staticClass:"object-cover border border-black rounded-full mr-3",attrs:{"src":_vm.systemAvatar,"alt":"app logo"}})]:_vm._e()],2),_c('div',{staticClass:"relative lg:max-w-md max-w-xs px-3 py-2 rounded-xl",class:[
              {
                'bg-white border border-grayedLight': !_vm.checkIsSender(message.sender.username),
                // Wlasne wiadomosci
                'bg-grayedLight text-black border border-grayedHR ml-12 lg:ml-0' : _vm.checkIsSender(message.sender.username),
                'rounded-tr-sm':_vm.isPreviousFromTheSameSender(message, index) && _vm.checkIsSender(message.sender.username)
                  && !_vm.isPreviousSeparated(message, index),
                'rounded-br-sm':_vm.isNextFromTheSameSender(message, index) && _vm.checkIsSender(message.sender.username)
                  && !_vm.isNextSeparated(message, index),
                // Cudze wiadomosci
                'mr-6 lg:mr-0 rounded-tl-sm':_vm.isPreviousFromTheSameSender(message, index) && !_vm.checkIsSender(message.sender.username)
                  && !_vm.isPreviousSeparated(message,index),
                'rounded-bl-sm':_vm.isNextFromTheSameSender(message, index) && !_vm.checkIsSender(message.sender.username)
                  && !_vm.isNextSeparated(message,index),
                'pt-1':_vm.isPreviousFromTheSameSender(message, index) && !_vm.isPreviousSeparated(message, index),
                'pb-1':_vm.isNextFromTheSameSender(message, index) && !_vm.isNextSeparated(message, index),
              }
            ]},[_c('span',{ref:index === _vm.messagesData.length - 1 ? 'lastMessage' : '',refInFor:true,staticClass:"block break-words text-sm leading-normal",domProps:{"innerHTML":_vm._s(message.content)}})])])],2)}),0)]),(_vm.currentThread && _vm.userData.isSuperAdmin)?_c('div',{ref:"messageForm",staticClass:"flex-none z-index-2 flex-1 row-span-4 row-start-8 row-end-13 messages-chat"},[_c('ChatForm',{key:_vm.onEnterSend,attrs:{"onEnterSend":!!_vm.onEnterSend,"editor-disabled-prop":_vm.currentThread.systemMessage !== null},on:{"scroll-chat":function($event){_vm.scrollToBottomPosition = true}},scopedSlots:_vm._u([{key:"onEnterSend",fn:function(){return [_c('CheckboxField',{staticClass:"hover:opacity-70 hover:text-redAccentLight",attrs:{"label":_vm.trans('LABEL:SendOnEnter')},model:{value:(_vm.onEnterSend),callback:function ($$v) {_vm.onEnterSend=$$v},expression:"onEnterSend"}})]},proxy:true}],null,false,4233851261)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }